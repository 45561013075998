export const codes = [
  {
    'country_name_it': 'Italia',
    'country_name_en': 'Italy',
    'at_code': 'n.d.'
  },
  {
    'country_name_it': 'Albania',
    'country_name_en': 'Albania',
    'at_code': 'Z100'
  },
  {
    'country_name_it': 'Andorra',
    'country_name_en': 'Andorra',
    'at_code': 'Z101'
  },
  {
    'country_name_it': 'Austria',
    'country_name_en': 'Austria',
    'at_code': 'Z102'
  },
  {
    'country_name_it': 'Belgio',
    'country_name_en': 'Belgium',
    'at_code': 'Z103'
  },
  {
    'country_name_it': 'Bulgaria',
    'country_name_en': 'Bulgaria',
    'at_code': 'Z104'
  },
  {
    'country_name_it': 'Danimarca',
    'country_name_en': 'Denmark',
    'at_code': 'Z107'
  },
  {
    'country_name_it': 'Finlandia',
    'country_name_en': 'Finland',
    'at_code': 'Z109'
  },
  {
    'country_name_it': 'Francia',
    'country_name_en': 'France',
    'at_code': 'Z110'
  },
  {
    'country_name_it': 'Germania',
    'country_name_en': 'Germany',
    'at_code': 'Z112'
  },
  {
    'country_name_it': 'Regno Unito',
    'country_name_en': 'United Kingdom',
    'at_code': 'Z114'
  },
  {
    'country_name_it': 'Grecia',
    'country_name_en': 'Greece',
    'at_code': 'Z115'
  },
  {
    'country_name_it': 'Irlanda',
    'country_name_en': 'Ireland',
    'at_code': 'Z116'
  },
  {
    'country_name_it': 'Islanda',
    'country_name_en': 'Iceland',
    'at_code': 'Z117'
  },
  {
    'country_name_it': 'Liechtenstein',
    'country_name_en': 'Liechtenstein',
    'at_code': 'Z119'
  },
  {
    'country_name_it': 'Lussemburgo',
    'country_name_en': 'Luxembourg',
    'at_code': 'Z120'
  },
  {
    'country_name_it': 'Malta',
    'country_name_en': 'Malta',
    'at_code': 'Z121'
  },
  {
    'country_name_it': 'Monaco',
    'country_name_en': 'Monaco',
    'at_code': 'Z123'
  },
  {
    'country_name_it': 'Norvegia',
    'country_name_en': 'Norway',
    'at_code': 'Z125'
  },
  {
    'country_name_it': 'Paesi Bassi',
    'country_name_en': 'Netherlands',
    'at_code': 'Z126'
  },
  {
    'country_name_it': 'Polonia',
    'country_name_en': 'Poland',
    'at_code': 'Z127'
  },
  {
    'country_name_it': 'Portogallo',
    'country_name_en': 'Portugal',
    'at_code': 'Z128'
  },
  {
    'country_name_it': 'Romania',
    'country_name_en': 'Romania',
    'at_code': 'Z129'
  },
  {
    'country_name_it': 'San Marino',
    'country_name_en': 'San Marino',
    'at_code': 'Z130'
  },
  {
    'country_name_it': 'Spagna',
    'country_name_en': 'Spain',
    'at_code': 'Z131'
  },
  {
    'country_name_it': 'Svezia',
    'country_name_en': 'Sweden',
    'at_code': 'Z132'
  },
  {
    'country_name_it': 'Svizzera',
    'country_name_en': 'Switzerland',
    'at_code': 'Z133'
  },
  {
    'country_name_it': 'Ucraina',
    'country_name_en': 'Ukraine',
    'at_code': 'Z138'
  },
  {
    'country_name_it': 'Ungheria',
    'country_name_en': 'Hungary',
    'at_code': 'Z134'
  },
  {
    'country_name_it': 'Federazione russa',
    'country_name_en': 'Russian Federation',
    'at_code': 'Z154'
  },
  {
    'country_name_it': 'Stato della Città del Vaticano',
    'country_name_en': 'Vatican City State',
    'at_code': 'Z106'
  },
  {
    'country_name_it': 'Estonia',
    'country_name_en': 'Estonia',
    'at_code': 'Z144'
  },
  {
    'country_name_it': 'Lettonia',
    'country_name_en': 'Latvia',
    'at_code': 'Z145'
  },
  {
    'country_name_it': 'Lituania',
    'country_name_en': 'Lithuania',
    'at_code': 'Z146'
  },
  {
    'country_name_it': 'Croazia',
    'country_name_en': 'Croatia',
    'at_code': 'Z149'
  },
  {
    'country_name_it': 'Slovenia',
    'country_name_en': 'Slovenia',
    'at_code': 'Z150'
  },
  {
    'country_name_it': 'Bosnia-Erzegovina',
    'country_name_en': 'Bosnia and Herzegovina',
    'at_code': 'Z153'
  },
  {
    'country_name_it': 'Macedonia del Nord',
    'country_name_en': 'North Macedonia',
    'at_code': 'Z148'
  },
  {
    'country_name_it': 'Moldova',
    'country_name_en': 'Moldova',
    'at_code': 'Z140'
  },
  {
    'country_name_it': 'Slovacchia',
    'country_name_en': 'Slovakia',
    'at_code': 'Z155'
  },
  {
    'country_name_it': 'Bielorussia',
    'country_name_en': 'Belarus',
    'at_code': 'Z139'
  },
  {
    'country_name_it': 'Repubblica ceca',
    'country_name_en': 'Czech Republic',
    'at_code': 'Z156'
  },
  {
    'country_name_it': 'Montenegro',
    'country_name_en': 'Montenegro',
    'at_code': 'Z159'
  },
  {
    'country_name_it': 'Serbia',
    'country_name_en': 'Serbia',
    'at_code': 'Z158'
  },
  {
    'country_name_it': 'Kosovo',
    'country_name_en': 'Kosovo',
    'at_code': 'Z160'
  },
  {
    'country_name_it': 'Afghanistan',
    'country_name_en': 'Afghanistan',
    'at_code': 'Z200'
  },
  {
    'country_name_it': 'Arabia Saudita',
    'country_name_en': 'Saudi Arabia',
    'at_code': 'Z203'
  },
  {
    'country_name_it': 'Bahrein',
    'country_name_en': 'Bahrain',
    'at_code': 'Z204'
  },
  {
    'country_name_it': 'Bangladesh',
    'country_name_en': 'Bangladesh',
    'at_code': 'Z249'
  },
  {
    'country_name_it': 'Bhutan',
    'country_name_en': 'Bhutan',
    'at_code': 'Z205'
  },
  {
    'country_name_it': 'Myanmar/Birmania',
    'country_name_en': 'Myanmar/Burma',
    'at_code': 'Z206'
  },
  {
    'country_name_it': 'Brunei Darussalam',
    'country_name_en': 'Brunei Darussalam',
    'at_code': 'Z207'
  },
  {
    'country_name_it': 'Cambogia',
    'country_name_en': 'Cambodia',
    'at_code': 'Z208'
  },
  {
    'country_name_it': 'Sri Lanka',
    'country_name_en': 'Sri Lanka',
    'at_code': 'Z209'
  },
  {
    'country_name_it': 'Cina',
    'country_name_en': 'China',
    'at_code': 'Z210'
  },
  {
    'country_name_it': 'Cipro',
    'country_name_en': 'Cyprus',
    'at_code': 'Z211'
  },
  {
    'country_name_it': 'Corea del Nord',
    'country_name_en': 'North Korea',
    'at_code': 'Z214'
  },
  {
    'country_name_it': 'Corea del Sud',
    'country_name_en': 'South Korea',
    'at_code': 'Z213'
  },
  {
    'country_name_it': 'Emirati Arabi Uniti',
    'country_name_en': 'United Arab Emirates',
    'at_code': 'Z215'
  },
  {
    'country_name_it': 'Filippine',
    'country_name_en': 'Philippines',
    'at_code': 'Z216'
  },
  {
    'country_name_it': 'Palestina',
    'country_name_en': 'Palestine',
    'at_code': 'Z161'
  },
  {
    'country_name_it': 'Giappone',
    'country_name_en': 'Japan',
    'at_code': 'Z219'
  },
  {
    'country_name_it': 'Giordania',
    'country_name_en': 'Jordan',
    'at_code': 'Z220'
  },
  {
    'country_name_it': 'India',
    'country_name_en': 'India',
    'at_code': 'Z222'
  },
  {
    'country_name_it': 'Indonesia',
    'country_name_en': 'Indonesia',
    'at_code': 'Z223'
  },
  {
    'country_name_it': 'Iran',
    'country_name_en': 'Iran',
    'at_code': 'Z224'
  },
  {
    'country_name_it': 'Iraq',
    'country_name_en': 'Iraq',
    'at_code': 'Z225'
  },
  {
    'country_name_it': 'Israele',
    'country_name_en': 'Israel',
    'at_code': 'Z226'
  },
  {
    'country_name_it': 'Kuwait',
    'country_name_en': 'Kuwait',
    'at_code': 'Z227'
  },
  {
    'country_name_it': 'Laos',
    'country_name_en': 'Laos',
    'at_code': 'Z228'
  },
  {
    'country_name_it': 'Libano',
    'country_name_en': 'Lebanon',
    'at_code': 'Z229'
  },
  {
    'country_name_it': 'Timor Leste',
    'country_name_en': 'Timor Leste',
    'at_code': 'Z242'
  },
  {
    'country_name_it': 'Maldive',
    'country_name_en': 'Maldives',
    'at_code': 'Z232'
  },
  {
    'country_name_it': 'Malaysia',
    'country_name_en': 'Malaysia',
    'at_code': 'Z247'
  },
  {
    'country_name_it': 'Mongolia',
    'country_name_en': 'Mongolia',
    'at_code': 'Z233'
  },
  {
    'country_name_it': 'Nepal',
    'country_name_en': 'Nepal',
    'at_code': 'Z234'
  },
  {
    'country_name_it': 'Oman',
    'country_name_en': 'Oman',
    'at_code': 'Z235'
  },
  {
    'country_name_it': 'Pakistan',
    'country_name_en': 'Pakistan',
    'at_code': 'Z236'
  },
  {
    'country_name_it': 'Qatar',
    'country_name_en': 'Qatar',
    'at_code': 'Z237'
  },
  {
    'country_name_it': 'Singapore',
    'country_name_en': 'Singapore',
    'at_code': 'Z248'
  },
  {
    'country_name_it': 'Siria',
    'country_name_en': 'Syria',
    'at_code': 'Z240'
  },
  {
    'country_name_it': 'Thailandia',
    'country_name_en': 'Thailand',
    'at_code': 'Z241'
  },
  {
    'country_name_it': 'Turchia',
    'country_name_en': 'Turkey',
    'at_code': 'Z243'
  },
  {
    'country_name_it': 'Vietnam',
    'country_name_en': 'Vietnam',
    'at_code': 'Z251'
  },
  {
    'country_name_it': 'Yemen',
    'country_name_en': 'Yemen',
    'at_code': 'Z246'
  },
  {
    'country_name_it': 'Kazakhstan',
    'country_name_en': 'Kazakhstan',
    'at_code': 'Z255'
  },
  {
    'country_name_it': 'Uzbekistan',
    'country_name_en': 'Uzbekistan',
    'at_code': 'Z259'
  },
  {
    'country_name_it': 'Armenia',
    'country_name_en': 'Armenia',
    'at_code': 'Z252'
  },
  {
    'country_name_it': 'Azerbaigian',
    'country_name_en': 'Azerbaijan',
    'at_code': 'Z253'
  },
  {
    'country_name_it': 'Georgia',
    'country_name_en': 'Georgia',
    'at_code': 'Z254'
  },
  {
    'country_name_it': 'Kirghizistan',
    'country_name_en': 'Kyrgyzstan',
    'at_code': 'Z256'
  },
  {
    'country_name_it': 'Tagikistan',
    'country_name_en': 'Tajikistan',
    'at_code': 'Z257'
  },
  {
    'country_name_it': 'Taiwan',
    'country_name_en': 'Taiwan',
    'at_code': 'Z217'
  },
  {
    'country_name_it': 'Turkmenistan',
    'country_name_en': 'Turkmenistan',
    'at_code': 'Z258'
  },
  {
    'country_name_it': 'Algeria',
    'country_name_en': 'Algeria',
    'at_code': 'Z301'
  },
  {
    'country_name_it': 'Angola',
    'country_name_en': 'Angola',
    'at_code': 'Z302'
  },
  {
    'country_name_it': 'Costa d\'Avorio',
    'country_name_en': 'Côte d\'Ivoire',
    'at_code': 'Z313'
  },
  {
    'country_name_it': 'Benin',
    'country_name_en': 'Benin',
    'at_code': 'Z314'
  },
  {
    'country_name_it': 'Botswana',
    'country_name_en': 'Botswana',
    'at_code': 'Z358'
  },
  {
    'country_name_it': 'Burkina Faso',
    'country_name_en': 'Burkina Faso',
    'at_code': 'Z354'
  },
  {
    'country_name_it': 'Burundi',
    'country_name_en': 'Burundi',
    'at_code': 'Z305'
  },
  {
    'country_name_it': 'Camerun',
    'country_name_en': 'Cameroon',
    'at_code': 'Z306'
  },
  {
    'country_name_it': 'Capo Verde',
    'country_name_en': 'Cape Verde',
    'at_code': 'Z307'
  },
  {
    'country_name_it': 'Repubblica Centrafricana',
    'country_name_en': 'Central African Republic',
    'at_code': 'Z308'
  },
  {
    'country_name_it': 'Ciad',
    'country_name_en': 'Chad',
    'at_code': 'Z309'
  },
  {
    'country_name_it': 'Comore',
    'country_name_en': 'Comoros',
    'at_code': 'Z310'
  },
  {
    'country_name_it': 'Congo',
    'country_name_en': 'Congo',
    'at_code': 'Z311'
  },
  {
    'country_name_it': 'Egitto',
    'country_name_en': 'Egypt',
    'at_code': 'Z336'
  },
  {
    'country_name_it': 'Etiopia',
    'country_name_en': 'Ethiopia',
    'at_code': 'Z315'
  },
  {
    'country_name_it': 'Gabon',
    'country_name_en': 'Gabon',
    'at_code': 'Z316'
  },
  {
    'country_name_it': 'Gambia',
    'country_name_en': 'Gambia',
    'at_code': 'Z317'
  },
  {
    'country_name_it': 'Ghana',
    'country_name_en': 'Ghana',
    'at_code': 'Z318'
  },
  {
    'country_name_it': 'Gibuti',
    'country_name_en': 'Djibouti',
    'at_code': 'Z361'
  },
  {
    'country_name_it': 'Guinea',
    'country_name_en': 'Guinea',
    'at_code': 'Z319'
  },
  {
    'country_name_it': 'Guinea-Bissau',
    'country_name_en': 'Guinea-Bissau',
    'at_code': 'Z320'
  },
  {
    'country_name_it': 'Guinea equatoriale',
    'country_name_en': 'Equatorial Guinea',
    'at_code': 'Z321'
  },
  {
    'country_name_it': 'Kenya',
    'country_name_en': 'Kenya',
    'at_code': 'Z322'
  },
  {
    'country_name_it': 'Lesotho',
    'country_name_en': 'Lesotho',
    'at_code': 'Z359'
  },
  {
    'country_name_it': 'Liberia',
    'country_name_en': 'Liberia',
    'at_code': 'Z325'
  },
  {
    'country_name_it': 'Libia',
    'country_name_en': 'Libya',
    'at_code': 'Z326'
  },
  {
    'country_name_it': 'Madagascar',
    'country_name_en': 'Madagascar',
    'at_code': 'Z327'
  },
  {
    'country_name_it': 'Malawi',
    'country_name_en': 'Malawi',
    'at_code': 'Z328'
  },
  {
    'country_name_it': 'Mali',
    'country_name_en': 'Mali',
    'at_code': 'Z329'
  },
  {
    'country_name_it': 'Marocco',
    'country_name_en': 'Morocco',
    'at_code': 'Z330'
  },
  {
    'country_name_it': 'Mauritania',
    'country_name_en': 'Mauritania',
    'at_code': 'Z331'
  },
  {
    'country_name_it': 'Maurizio',
    'country_name_en': 'Mauritius',
    'at_code': 'Z332'
  },
  {
    'country_name_it': 'Mozambico',
    'country_name_en': 'Mozambique',
    'at_code': 'Z333'
  },
  {
    'country_name_it': 'Namibia',
    'country_name_en': 'Namibia',
    'at_code': 'Z300'
  },
  {
    'country_name_it': 'Niger',
    'country_name_en': 'Niger',
    'at_code': 'Z334'
  },
  {
    'country_name_it': 'Nigeria',
    'country_name_en': 'Nigeria',
    'at_code': 'Z335'
  },
  {
    'country_name_it': 'Ruanda',
    'country_name_en': 'Rwanda',
    'at_code': 'Z338'
  },
  {
    'country_name_it': 'Sao Tomé e Principe',
    'country_name_en': 'Sao Tome and Principe',
    'at_code': 'Z341'
  },
  {
    'country_name_it': 'Seychelles',
    'country_name_en': 'Seychelles',
    'at_code': 'Z342'
  },
  {
    'country_name_it': 'Senegal',
    'country_name_en': 'Senegal',
    'at_code': 'Z343'
  },
  {
    'country_name_it': 'Sierra Leone',
    'country_name_en': 'Sierra Leone',
    'at_code': 'Z344'
  },
  {
    'country_name_it': 'Somalia',
    'country_name_en': 'Somalia',
    'at_code': 'Z345'
  },
  {
    'country_name_it': 'Sudafrica',
    'country_name_en': 'South Africa',
    'at_code': 'Z347'
  },
  {
    'country_name_it': 'Sudan',
    'country_name_en': 'Sudan',
    'at_code': 'Z348'
  },
  {
    'country_name_it': 'Eswatini',
    'country_name_en': 'Eswatini',
    'at_code': 'Z349'
  },
  {
    'country_name_it': 'Tanzania',
    'country_name_en': 'Tanzania',
    'at_code': 'Z357'
  },
  {
    'country_name_it': 'Togo',
    'country_name_en': 'Togo',
    'at_code': 'Z351'
  },
  {
    'country_name_it': 'Tunisia',
    'country_name_en': 'Tunisia',
    'at_code': 'Z352'
  },
  {
    'country_name_it': 'Uganda',
    'country_name_en': 'Uganda',
    'at_code': 'Z353'
  },
  {
    'country_name_it': 'Repubblica Democratica del Congo',
    'country_name_en': 'Democratic Republic of the Congo',
    'at_code': 'Z312'
  },
  {
    'country_name_it': 'Zambia',
    'country_name_en': 'Zambia',
    'at_code': 'Z355'
  },
  {
    'country_name_it': 'Zimbabwe',
    'country_name_en': 'Zimbabwe',
    'at_code': 'Z337'
  },
  {
    'country_name_it': 'Eritrea',
    'country_name_en': 'Eritrea',
    'at_code': 'Z368'
  },
  {
    'country_name_it': 'Sud Sudan',
    'country_name_en': 'South Sudan',
    'at_code': 'Z907'
  },
  {
    'country_name_it': 'Antigua e Barbuda',
    'country_name_en': 'Antigua and Barbuda',
    'at_code': 'Z532'
  },
  {
    'country_name_it': 'Bahamas',
    'country_name_en': 'Bahamas',
    'at_code': 'Z502'
  },
  {
    'country_name_it': 'Barbados',
    'country_name_en': 'Barbados',
    'at_code': 'Z522'
  },
  {
    'country_name_it': 'Belize',
    'country_name_en': 'Belize',
    'at_code': 'Z512'
  },
  {
    'country_name_it': 'Canada',
    'country_name_en': 'Canada',
    'at_code': 'Z401'
  },
  {
    'country_name_it': 'Costa Rica',
    'country_name_en': 'Costa Rica',
    'at_code': 'Z503'
  },
  {
    'country_name_it': 'Cuba',
    'country_name_en': 'Cuba',
    'at_code': 'Z504'
  },
  {
    'country_name_it': 'Dominica',
    'country_name_en': 'Dominica',
    'at_code': 'Z526'
  },
  {
    'country_name_it': 'Repubblica Dominicana',
    'country_name_en': 'Dominican Republic',
    'at_code': 'Z505'
  },
  {
    'country_name_it': 'El Salvador',
    'country_name_en': 'El Salvador',
    'at_code': 'Z506'
  },
  {
    'country_name_it': 'Giamaica',
    'country_name_en': 'Jamaica',
    'at_code': 'Z507'
  },
  {
    'country_name_it': 'Grenada',
    'country_name_en': 'Grenada',
    'at_code': 'Z524'
  },
  {
    'country_name_it': 'Guatemala',
    'country_name_en': 'Guatemala',
    'at_code': 'Z509'
  },
  {
    'country_name_it': 'Haiti',
    'country_name_en': 'Haiti',
    'at_code': 'Z510'
  },
  {
    'country_name_it': 'Honduras',
    'country_name_en': 'Honduras',
    'at_code': 'Z511'
  },
  {
    'country_name_it': 'Messico',
    'country_name_en': 'Mexico',
    'at_code': 'Z514'
  },
  {
    'country_name_it': 'Nicaragua',
    'country_name_en': 'Nicaragua',
    'at_code': 'Z515'
  },
  {
    'country_name_it': 'Panama',
    'country_name_en': 'Panama',
    'at_code': 'Z516'
  },
  {
    'country_name_it': 'Santa Lucia',
    'country_name_en': 'Saint Lucia',
    'at_code': 'Z527'
  },
  {
    'country_name_it': 'Saint Vincent e Grenadine',
    'country_name_en': 'Saint Vincent and the Grenadines',
    'at_code': 'Z528'
  },
  {
    'country_name_it': 'Saint Kitts e Nevis',
    'country_name_en': 'Saint Kitts and Nevis',
    'at_code': 'Z533'
  },
  {
    'country_name_it': 'Stati Uniti d\'America',
    'country_name_en': 'United States of America',
    'at_code': 'Z404'
  },
  {
    'country_name_it': 'Argentina',
    'country_name_en': 'Argentina',
    'at_code': 'Z600'
  },
  {
    'country_name_it': 'Bolivia',
    'country_name_en': 'Bolivia',
    'at_code': 'Z601'
  },
  {
    'country_name_it': 'Brasile',
    'country_name_en': 'Brazil',
    'at_code': 'Z602'
  },
  {
    'country_name_it': 'Cile',
    'country_name_en': 'Chile',
    'at_code': 'Z603'
  },
  {
    'country_name_it': 'Colombia',
    'country_name_en': 'Colombia',
    'at_code': 'Z604'
  },
  {
    'country_name_it': 'Ecuador',
    'country_name_en': 'Ecuador',
    'at_code': 'Z605'
  },
  {
    'country_name_it': 'Guyana',
    'country_name_en': 'Guyana',
    'at_code': 'Z606'
  },
  {
    'country_name_it': 'Paraguay',
    'country_name_en': 'Paraguay',
    'at_code': 'Z610'
  },
  {
    'country_name_it': 'Perù',
    'country_name_en': 'Peru',
    'at_code': 'Z611'
  },
  {
    'country_name_it': 'Suriname',
    'country_name_en': 'Suriname',
    'at_code': 'Z608'
  },
  {
    'country_name_it': 'Trinidad e Tobago',
    'country_name_en': 'Trinidad and Tobago',
    'at_code': 'Z612'
  },
  {
    'country_name_it': 'Uruguay',
    'country_name_en': 'Uruguay',
    'at_code': 'Z613'
  },
  {
    'country_name_it': 'Venezuela',
    'country_name_en': 'Venezuela',
    'at_code': 'Z614'
  },
  {
    'country_name_it': 'Australia',
    'country_name_en': 'Australia',
    'at_code': 'Z700'
  },
  {
    'country_name_it': 'Figi',
    'country_name_en': 'Fiji',
    'at_code': 'Z704'
  },
  {
    'country_name_it': 'Kiribati',
    'country_name_en': 'Kiribati',
    'at_code': 'Z731'
  },
  {
    'country_name_it': 'Isole Marshall',
    'country_name_en': 'Marshall Islands',
    'at_code': 'Z711'
  },
  {
    'country_name_it': 'Stati Federati di Micronesia',
    'country_name_en': 'Federated States of Micronesia',
    'at_code': 'Z735'
  },
  {
    'country_name_it': 'Nauru',
    'country_name_en': 'Nauru',
    'at_code': 'Z713'
  },
  {
    'country_name_it': 'Nuova Zelanda',
    'country_name_en': 'New Zealand',
    'at_code': 'Z719'
  },
  {
    'country_name_it': 'Palau',
    'country_name_en': 'Palau',
    'at_code': 'Z734'
  },
  {
    'country_name_it': 'Papua Nuova Guinea',
    'country_name_en': 'Papua New Guinea',
    'at_code': 'Z730'
  },
  {
    'country_name_it': 'Isole Salomone',
    'country_name_en': 'Solomon Islands',
    'at_code': 'Z724'
  },
  {
    'country_name_it': 'Samoa',
    'country_name_en': 'Samoa',
    'at_code': 'Z726'
  },
  {
    'country_name_it': 'Tonga',
    'country_name_en': 'Tonga',
    'at_code': 'Z728'
  },
  {
    'country_name_it': 'Tuvalu',
    'country_name_en': 'Tuvalu',
    'at_code': 'Z732'
  },
  {
    'country_name_it': 'Vanuatu',
    'country_name_en': 'Vanuatu',
    'at_code': 'Z733'
  },
  {
    'country_name_it': 'Nuova Caledonia',
    'country_name_en': 'New Caledonia',
    'at_code': 'Z716'
  },
  {
    'country_name_it': 'Saint-Martin (FR)',
    'country_name_en': 'Saint Martin (FR)',
    'at_code': 'n.d.'
  },
  {
    'country_name_it': 'Sahara occidentale',
    'country_name_en': 'Western Sahara',
    'at_code': 'Z339'
  },
  {
    'country_name_it': 'Saint-Barthélemy',
    'country_name_en': 'Saint Barthelemy',
    'at_code': 'n.d.'
  },
  {
    'country_name_it': 'Bermuda',
    'country_name_en': 'Bermuda',
    'at_code': 'Z400'
  },
  {
    'country_name_it': 'Isole Cook (NZ)',
    'country_name_en': 'Cook Islands (NZ)',
    'at_code': 'Z703'
  },
  {
    'country_name_it': 'Gibilterra',
    'country_name_en': 'Gibraltar',
    'at_code': 'Z113'
  },
  {
    'country_name_it': 'Isole Cayman',
    'country_name_en': 'Cayman Islands',
    'at_code': 'Z530'
  },
  {
    'country_name_it': 'Anguilla',
    'country_name_en': 'Anguilla',
    'at_code': 'Z529'
  },
  {
    'country_name_it': 'Polinesia francese',
    'country_name_en': 'French Polynesia',
    'at_code': 'Z723'
  },
  {
    'country_name_it': 'Isole Fær Øer',
    'country_name_en': 'Faroe Islands',
    'at_code': 'Z108'
  },
  {
    'country_name_it': 'Jersey',
    'country_name_en': 'Jersey',
    'at_code': 'n.d.'
  },
  {
    'country_name_it': 'Aruba',
    'country_name_en': 'Aruba',
    'at_code': 'Z501'
  },
  {
    'country_name_it': 'Sint Maarten (NL)',
    'country_name_en': 'St Maarten (NL)',
    'at_code': 'n.d.'
  },
  {
    'country_name_it': 'Groenlandia',
    'country_name_en': 'Greenland',
    'at_code': 'Z402'
  },
  {
    'country_name_it': 'Sark',
    'country_name_en': 'Sark',
    'at_code': 'n.d.'
  },
  {
    'country_name_it': 'Guernsey',
    'country_name_en': 'Guernsey',
    'at_code': 'n.d.'
  },
  {
    'country_name_it': 'Isole Falkland (Malvine)',
    'country_name_en': 'Falkland Islands (Malvinas)',
    'at_code': 'Z609'
  },
  {
    'country_name_it': 'Isola di Man',
    'country_name_en': 'Isle of Man',
    'at_code': 'Z122'
  },
  {
    'country_name_it': 'Montserrat',
    'country_name_en': 'Montserrat',
    'at_code': 'Z531'
  },
  {
    'country_name_it': 'Curaçao',
    'country_name_en': 'Curaçao',
    'at_code': 'n.d.'
  },
  {
    'country_name_it': 'Isole Pitcairn',
    'country_name_en': 'Pitcairn',
    'at_code': 'Z722'
  },
  {
    'country_name_it': 'Saint Pierre e Miquelon',
    'country_name_en': 'Saint Pierre and Miquelon',
    'at_code': 'Z403'
  },
  {
    'country_name_it': 'Sant\'Elena',
    'country_name_en': 'Saint Helena',
    'at_code': 'Z340'
  },
  {
    'country_name_it': 'Terre australi e antartiche francesi',
    'country_name_en': 'French Southern Territories',
    'at_code': 'n.d.'
  },
  {
    'country_name_it': 'Isole Turks e Caicos',
    'country_name_en': 'Turks and Caicos Islands',
    'at_code': 'Z519'
  },
  {
    'country_name_it': 'Isole Vergini britanniche',
    'country_name_en': 'British Virgin Islands',
    'at_code': 'Z525'
  },
  {
    'country_name_it': 'Wallis e Futuna',
    'country_name_en': 'Wallis and Futuna Islands',
    'at_code': 'Z729'
  }
]