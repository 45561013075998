<template>
  <div class="referrals" :style="pages > 1 ? 'padding-bottom: 0;' : ''">
    <div class="filters">
      <div class="search">
        <label for="search">Cerca</label>
        <input id="search" type="text" name="" placeholder="nome, cognome e codice fiscale" @input="filterSearch($event)">
      </div>

      <div class="lens">
        <font-awesome-icon icon="magnifying-glass" class="fa-lg" />
      </div>

      <div class="btn-confirm">
        <material-button v-if="permissions.canUse('DATA_INVOICE_MANAGE')" text="RIFIUTA" type="danger" :disabled="!getFilteredRows().find((f) => f.selected)" @click="rejetSelectedRows()" />
        <span v-show="getFilteredRows().filter((f) => f.selected).length" class="counter-badge">
          {{ getFilteredRows().filter((f) => f.selected).length }}
        </span>
      </div>

      <div class="btn-confirm">
        <material-button v-if="permissions.canUse('DATA_INVOICE_MANAGE')" text="APPROVA" :disabled="!getFilteredRows().find((f) => f.selected)" @click="approveSelectedRows()" />
        <span v-show="getFilteredRows().filter((f) => f.selected).length" class="counter-badge">
          {{ getFilteredRows().filter((f) => f.selected).length }}
        </span>
      </div>
    </div>

    <div class="table">
      <table cellspacing="0">
        <thead>
          <tr>
            <th class="checkbox">
              <input id="mainCheckbox" type="checkbox" @click="toggleRowsSelection($event.target.checked)">
            </th>
            <th v-for="header of headers" :key="header" class="orderable" @click="changeOrder(header.field)">
              <div class="">
                <span v-html="header.title" />
                <font-awesome-icon v-if="(filters.order.field == header.field)" :icon="filters.order.asc ? 'arrow-down-a-z' : 'arrow-down-z-a'" />
              </div>
            </th>
          </tr>
        </thead>

        <tbody class="table-body">
          <tr v-for="document in getFilteredRows()" :key="document.id">
            <td class="checkbox">
              <input :checked="document.selected" :disabled="document.approvalStatus !== 'to_be_approved'" type="checkbox" @click="selectRow(document.id)">
            </td>
            <td>
              <p>{{ document.firstNameReferral }} {{ document.lastNameReferral }}</p>
              <p>{{ document.email }}</p>
            </td>
            <td>
              <div style="display:flex; align-items:center">
                <div>
                  <div v-if="document.birthPlace && document.birthProvince">
                    {{ document.birthPlace }} ({{ document.birthProvince }})
                  </div>
                  <div v-else>
                    -
                  </div>

                  <div v-if="document.birthDate" style="margin-top:10px">
                    {{ dayjs(document.birthDate).format('DD MMMM YYYY') }}
                  </div>
                  <div v-else>
                    -
                  </div>
                </div>
              </div>
            </td>
            <td>
              <div style="display:flex">
                <Tooltip :text="'Codice fiscale estero'" :disabled="document.birthProvince && document.birthProvince.includes('ESTERO') ? false : true">
                  <svg style="width:18px;height:18px" viewBox="0 0 24 24">
                    <path :fill="document.birthProvince && document.birthProvince.includes('ESTERO') ? 'white' : 'transparent'" d="M17.9,17.39C17.64,16.59 16.89,16 16,16H15V13A1,1 0 0,0 14,12H8V10H10A1,1 0 0,0 11,9V7H13A2,2 0 0,0 15,5V4.59C17.93,5.77 20,8.64 20,12C20,14.08 19.2,15.97 17.9,17.39M11,19.93C7.05,19.44 4,16.08 4,12C4,11.38 4.08,10.78 4.21,10.21L9,15V16A2,2 0 0,0 11,18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                  </svg>
                </Tooltip>

                <span style="margin:0 10px; min-width:140px">{{ document.fiscalCode.toString().toUpperCase() }}</span>
                <Tooltip :text="!document.birthDate ? 'Il candidato non ha inserito i dati per la verifica del codice fiscale' : checkCodiceFiscale(document) ? 'Il codice fiscale è valido' : 'Il codice fiscale non è valido'" :disabled="document.fiscalCode ? false : true">
                  <svg v-if="!document.birthDate && document.fiscalCode" style="width:20px;height:20px" viewBox="0 0 24 24">
                    <path fill="yellow" d="M11,18H13V16H11V18M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,6A4,4 0 0,0 8,10H10A2,2 0 0,1 12,8A2,2 0 0,1 14,10C14,12 11,11.75 11,15H13C13,12.75 16,12.5 16,10A4,4 0 0,0 12,6Z" />
                  </svg>
                  <svg v-else-if="document.fiscalCode && checkCodiceFiscale(document)" style="width:20px;height:20px" viewBox="0 0 24 24">
                    <path fill="#bcde42" d="M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2,4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z" />
                  </svg>
                  <svg v-else-if="document.fiscalCode && !checkCodiceFiscale(document)" style="width:20px;height:20px" viewBox="0 0 24 24">
                    <path fill="#dc3545" d="M19,19H5V5H19M19,3H5A2,2 0 0,0 3,5V19A2,2 0 0,0 5,21H19A2,2 0 0,0 21,19V5C21,3.89 20.1,3 19,3M11,15H13V17H11V15M11,7H13V13H11V7" />
                  </svg>
                </Tooltip>
              </div>
            </td>
            <td>
              {{ dayjs(document.uploadFileDate, 'YYYY-MM-DD').format('DD MMMM YYYY') }}
              <p class="text-xs" style="margin-top:10px">
                {{ dayjs(document.uploadFileDate, 'YYYY-MM-DD HH:mm:ss').format('HH:mm:ss') }}
              </p>
            </td>
            <td>
              <div>
                <span v-if="document.documentType === 0">CARTA D'IDENTITÀ</span>
                <span v-else-if="document.documentType === 1">PATENTE</span>
                <span v-else-if="document.documentType === 2">PASSAPORTO</span>
              </div>
              <div style="margin-top:10px">
                {{ dayjs(document.documentExpirationDate, 'YYYY-MM-DD').format('DD MMMM YYYY') }}
              </div>
            </td>
            <td class="file-link">
              <Tooltip :text="document.fileFront" :disabled="document?.fileFront?.length < 20">
                <div class="inline-row" style="margin-bottom:15px" @click="downloadPDF(document.uidReferral, document.fileFront)">
                  <svg v-if="false" style="width:20px;height:20px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M16.5,6V17.5A4,4 0 0,1 12.5,21.5A4,4 0 0,1 8.5,17.5V5A2.5,2.5 0 0,1 11,2.5A2.5,2.5 0 0,1 13.5,5V15.5A1,1 0 0,1 12.5,16.5A1,1 0 0,1 11.5,15.5V6H10V15.5A2.5,2.5 0 0,0 12.5,18A2.5,2.5 0 0,0 15,15.5V5A4,4 0 0,0 11,1A4,4 0 0,0 7,5V17.5A5.5,5.5 0 0,0 12.5,23A5.5,5.5 0 0,0 18,17.5V6H16.5Z" />
                  </svg>
                  <img src="../assets/front.png" height="14">

                  <span class="text-underline" v-text="getShorterLabel(document.fileFront)" />
                </div>
              </Tooltip>
            </td>
            <td class="file-link">
              <Tooltip v-if="document?.fileBack && document?.fileBack.length > 0" :text="document.fileBack" :disabled="document?.fileBack?.length < 20">
                <div class="inline-row" @click="downloadPDF(document.uidReferral, document.fileBack)">
                  <svg v-if="false" style="width:20px;height:20px" viewBox="0 0 24 24">
                    <path fill="currentColor" d="M16.5,6V17.5A4,4 0 0,1 12.5,21.5A4,4 0 0,1 8.5,17.5V5A2.5,2.5 0 0,1 11,2.5A2.5,2.5 0 0,1 13.5,5V15.5A1,1 0 0,1 12.5,16.5A1,1 0 0,1 11.5,15.5V6H10V15.5A2.5,2.5 0 0,0 12.5,18A2.5,2.5 0 0,0 15,15.5V5A4,4 0 0,0 11,1A4,4 0 0,0 7,5V17.5A5.5,5.5 0 0,0 12.5,23A5.5,5.5 0 0,0 18,17.5V6H16.5Z" />
                  </svg>
                  <img src="../assets/back.png" height="14">
                  <span class="text-underline" v-text="getShorterLabel(document.fileBack)" />
                </div>
              </Tooltip>
            </td>
            <td class="tdFlex">
              <div>
                <div class="fiscal_code_container">
                  <div class="fiscal_code">
                    <svg v-if="checkCodiceFiscale(document)" class="check-ok" viewBox="0 0 24 24">
                      <path fill="green" d="M12 2C6.5 2 2 6.5 2 12S6.5 22 12 22 22 17.5 22 12 17.5 2 12 2M10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z" />
                    </svg>
                    <svg v-else class="check-fail" viewBox="0 0 24 24">
                      <path fill="#dc3545" d="M13,13H11V7H13M13,17H11V15H13M12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0,0 22,12A10,10 0 0,0 12,2Z" />
                    </svg>
                  </div>

                  {{ document.cf }}
                </div>
                <span v-if="document.approvalStatus === 'to_be_approved'">DA APPROVARE</span>
                <span v-else-if="document.approvalStatus === 'approved'">APPROVATO</span>
                <span v-else-if="document.approvalStatus === 'unapproved'">RIFIUTATO</span>
                <span v-else-if="document.approvalStatus === 'expired'">SCADUTO</span>
              </div>
            </td>
            <td>{{ document.firstNameApprover }} {{ document.lastNameApprover }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <div v-if="pages > 1" class="pagination">
      <div>Visualizzati {{ numberOfElements }} di {{ totalElements }}</div>
      <v-pagination v-model="page" :pages="pages" active-color="#bcde42" @update:modelValue="getDataInvoice" />
    </div>
  </div>

  <Dialog ref="pdfPreviewDialog" :pdf-url="pdfUrlToOpen" size="preview" />
</template>

<script>
import { onMounted, reactive, toRefs, inject, ref } from 'vue'
import { sbapibackoffice } from '@/api'
import { spinnerStore } from '@/stores/spinner'
import { navbarStore } from '@/stores/navbar'
import dayjs from 'dayjs'
import { codes } from '../codes'
require('dayjs/locale/it')
import Dialog from '../components/Dialog.vue'
import Tooltip from '../components/Tooltip'
import codicefiscale from 'codice-fiscale-js'
import VPagination from '@hennge/vue3-pagination'
import '@hennge/vue3-pagination/dist/vue3-pagination.css'
import { permissionsStore } from '@/stores/permissions'

export default {
  components: {
    Dialog,
    Tooltip,
    VPagination
  },
  setup() {
    dayjs.locale('it')
    const spinner = spinnerStore()
    const navbar = navbarStore()
    const toast = inject('$toast')
    const pdfPreviewDialog = ref('pdfPreviewDialog')
    const permissions = permissionsStore()

    const state = reactive({
      headers: [
        { title: 'Nome e cognome', field: 'firstName' },
        { title: 'Luogo e<p> data di nascita</p>', field: 'birthDate' },
        { title: 'Codice Fiscale', field: 'fiscalCode' },
        { title: 'Data caricamento', field: 'uploadFileDate' },
        { title: 'Tipologia /<p>Scadenza doc.</p>', field: 'documentExpirationDate' },
        { title: 'Documento Fronte', field: 'fileFront' },
        { title: 'Documento Retro</p>', field: 'fileBack' },
        { title: 'Stato', field: 'approvalStatus' },
        { title: 'Approvato da', field: 'firstName' }
      ],
      filters: {
        order: {
          field: 'uploadFileDate',
          asc: false
        },
        search: ''
      },
      documents: [],
      dayjs,
      pdfUrlToOpen: '',
      page: 1,
      pages: 0,
      numberOfElements: 0,
      totalElements: 0
    })

    onMounted(() => {
      navbar.title = 'Documenti di riconoscimento'
      
      getDataInvoice()
    })

    const getDataInvoice = () => {
      spinner.show()
      sbapibackoffice
        .get('/referrals/dataInvoice', {
          params: {
            page: state.page - 1,
            size: 10,
            sort: `${state.filters.order.field},${state.filters.order.asc ? 'asc' : 'desc'}`,
            search: state.filters.search || undefined
          }
        })
        .then(({ data }) => {
          state.documents = data.content
          state.pages = data.totalPages
          state.totalElements = data.totalElements
          state.numberOfElements = data.numberOfElements
        })
        .catch((err) => {
          console.log(err)
        })
        .finally(spinner.hide)
    }

    /**
     * Set the order condition for the table
     * @param {number} orderBy - the column to order by
     */
    const changeOrder = (orderBy) => {
      if (state.filters.order.field == orderBy) {
        state.filters.order.asc = !state.filters.order.asc
      } else {
        state.filters.order.field = orderBy
        state.filters.order.asc = true
      }

      getDataInvoice()
    }

    const getFilteredRows = () => {
      const rows = state.documents
      return rows
    }

    const toggleRowsSelection = (checked) => {
      state.documents.forEach((d) => {
        if (d.approvalStatus === 'to_be_approved') d.selected = checked
      })
    }

    /**
     * select a row by id
     * @param  {number} id document ID
     */
    const selectRow = (id) => {
      state.documents.forEach((d) => {
        if (d.id == id) d.selected = !d.selected
      })
    }

    const rejetSelectedRows = () => {
      const promises = []
      spinner.show()

      state.documents.filter(f => f.selected).forEach((d) => {
        promises.push(
          sbapibackoffice.put('/referral/dataInvoice', {
            id: d.id,
            approvalStatus: 'unapproved'
          })
        )
      })
      Promise.all(promises)
        .then(() => {
          toast.success('Documenti rifiutati con successo')
          state.documents.filter(f => f.selected).forEach((d) => {
            d.approvalStatus = 'unapproved'
          })
        })
        .catch((err) => {
          console.log(err)
        })
        .then(() => {
          spinner.hide()
        })
    }

    const approveSelectedRows = () => {
      const promises = []
      spinner.show()

      state.documents.filter(f => f.selected).forEach((d) => {
        promises.push(
          sbapibackoffice.put('/referral/dataInvoice', {
            id: d.id,
            approvalStatus: 'approved'
          })
        )
      })
      Promise.all(promises)
        .then(() => {
          toast.success('Documenti approvati con successo')
          state.documents.filter(f => f.selected).forEach((d) => {
            d.approvalStatus = 'approved'
          })
        })
        .catch((err) => {
          console.log(err)
        })
        .then(() => {
          spinner.hide()
        })
    }

    const downloadPDF = (uid, fileName) => {
      spinner.show()
      sbapibackoffice
        .get(`/referral/document/url?type=0&uid=${uid}&fileName=${encodeURIComponent(fileName)}`)
        .then(({ data }) => {
          state.pdfUrlToOpen = data
          pdfPreviewDialog.value.open(state.pdfUrlToOpen)
          //window.open(data, "_blank").focus();
        })
        .catch((error) => {
          toast.error('Qualcosa è andato storto')
          console.log(error)
        })
        .then(() => {
          spinner.hide()
        })
    }

    const getShorterLabel = (label) => {
      let l = label
      if (l && l.length > 20) {
        l = l.slice(0, 10) + '...' + l.slice(l.length - 3, l.length)
      }
      return l
    }

    // used to manage the search input debounce time
    const typingTimer = ref(null)

    const filterSearch = ({ target }) => {
      state.filters.search = target.value

      // execute getReferrals after 500ms of non typing
      clearTimeout(typingTimer.value)
      typingTimer.value = setTimeout(() => {
        state.page = 1
        getDataInvoice()
      }, 1000)
    }
    const checkCodiceFiscale = (doc) => {
      if (!doc.birthDate || !doc.birthPlace) return
      let newCfFemale = null
      let newCfMale = null
      let isSame = null
      let cf = null
      let isDateValid = null
      let isProvinceValid = null
      if (doc.birthProvince.includes('ESTERO')) {
        try {
          newCfMale = new codicefiscale({
            name: doc.firstNameReferral,
            surname: doc.lastNameReferral,
            gender: 'M',
            day: dayjs(doc.birthDate, 'YYYY-MM-DD').format('DD'),
            month: dayjs(doc.birthDate, 'YYYY-MM-DD').format('MM'),
            year: dayjs(doc.birthDate, 'YYYY-MM-DD').format('YYYY'),
            birthplace: doc.birthForeignState,
            birthplaceProvincia: 'EE'
          })

          newCfFemale = new codicefiscale({
            name: doc.firstNameReferral,
            surname: doc.lastNameReferral,
            gender: 'F',
            day: dayjs(doc.birthDate, 'YYYY-MM-DD').format('DD'),
            month: dayjs(doc.birthDate, 'YYYY-MM-DD').format('MM'),
            year: dayjs(doc.birthDate, 'YYYY-MM-DD').format('YYYY'),
            birthplace: doc.birthForeignState,
            birthplaceProvincia: 'EE'
          })
          isSame = (doc.fiscalCode === newCfFemale.code) || (doc.fiscalCode === newCfMale.code)
          cf = new codicefiscale(doc.fiscalCode)
          isDateValid = dayjs(cf.birthday, 'YYYY-MM-DD').format('YYYY-MM-DD') === dayjs(doc.birthDate).format('YYYY-MM-DD')

          // Controllo che gli ultimi 4 caratteri alfa numerifici ( ad esclusione dell'ultima lettera ) del cf siano uguali al codice at di codes.js
          let cfCode = null
          if (newCfMale) {
            cfCode = newCfMale.code.toString().slice(newCfMale.code.length - 5, newCfMale.code.length - 1)
          } else {
            cfCode = newCfFemale.code.toString().slice(newCfFemale.code.length - 5, newCfFemale.code.length - 1)

          }
          let state = codes.find(c => {
            return c.country_name_it.toLowerCase() == doc.birthForeignState.toLowerCase() || c.country_name_en.toLowerCase() == doc.birthForeignState.toLowerCase()
          })
          return isSame && isDateValid && state?.at_code === cfCode
        } catch (error) {
          console.log(error)
          return false
        }
      } else {
        try {
          newCfMale = new codicefiscale({
            name: doc.firstNameReferral,
            surname: doc.lastNameReferral,
            gender: 'M',
            day: dayjs(doc.birthDate, 'YYYY-MM-DD').format('DD'),
            month: dayjs(doc.birthDate, 'YYYY-MM-DD').format('MM'),
            year: dayjs(doc.birthDate, 'YYYY-MM-DD').format('YYYY'),
            birthplace: doc.birthPlace,
            birthplaceProvincia: doc.birthProvince
          })

          newCfFemale = new codicefiscale({
            name: doc.firstNameReferral,
            surname: doc.lastNameReferral,
            gender: 'F',
            day: dayjs(doc.birthDate, 'YYYY-MM-DD').format('DD'),
            month: dayjs(doc.birthDate, 'YYYY-MM-DD').format('MM'),
            year: dayjs(doc.birthDate, 'YYYY-MM-DD').format('YYYY'),
            birthplace: doc.birthPlace,
            birthplaceProvincia: doc.birthProvince
          })

          isSame = (doc.fiscalCode === newCfFemale.code) || (doc.fiscalCode === newCfMale.code)

          cf = new codicefiscale(doc.fiscalCode)

          isDateValid = dayjs(cf.birthday, 'YYYY-MM-DD').format('YYYY-MM-DD') === dayjs(doc.birthDate).format('YYYY-MM-DD')
          isProvinceValid = cf.birthplace.prov.toString().toUpperCase() === doc.birthProvince.toString().toUpperCase()

          return isSame && isDateValid && isProvinceValid
        } catch (error) {
          return false
        }
      }
    }

    return {
      ...toRefs(state),
      getFilteredRows,
      changeOrder,
      toggleRowsSelection,
      selectRow,
      rejetSelectedRows,
      approveSelectedRows,
      downloadPDF,
      pdfPreviewDialog,
      getShorterLabel,
      filterSearch,
      checkCodiceFiscale,
      getDataInvoice,
      permissions
    }
  }
}
</script>
<style lang="scss">
.referrals table tr th.orderable:nth-child(4) {
  justify-content: center !important;

  span {
    width: 100%;
    justify-content: center !important;
    //background: yellow;
    text-align: center
  }
}

.pagination {
  padding: 20px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ul.Pagination {
    justify-content: flex-end;

    li.PaginationControl>path {
      fill: rgba(255, 255, 255, 0.233);
    }

    .Control-active {
      fill: white;
    }

    .Page {
      width: 25px;
      height: 25px;
      color: white;
    }

    button.Page-active {
      background: var(--accented) !important;
      border: 1px solid var(--accented) !important;
      color: #003e73;
      font-size: 14px;
      font-weight: 600;
    }
  }
}
</style>

<style lang="scss" scoped>
@use "../assets/scss/referrals";
@use "../assets/scss/table";

.fiscal_code_container {
  display: flex;
  align-items: center;

  .fiscal_code {
    position: relative;
    background: rgb(255, 255, 255);
    border-radius: 50%;
    width: 15px;
    height: 15px;
    margin-right: 10px;

    svg {
      position: absolute;
      top: -5px;
      left: -5px;
      width: 25px;
      height: 25px;
      min-width: 25px;
      min-height: 25px;

      &.check-ok {
        path {
          fill: var(--accented)
        }
      }

      &.check-fail {
        path {
          fill: var(-danger)
        }
      }
    }
  }
}

.tdFlex {
  div {
    display: flex;
    align-items: center;
  }
}
</style>